.MuiTooltip-tooltip {
    background-color: $blue1 !important;
    text-align: center !important;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border-radius: 2px !important;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $white !important;
    text-transform: initial !important;
    margin-right: 8px !important;
}

.MuiTooltip-arrow::before {
    background-color: $blue1 !important;
}

.snackbar-error {
    background-color: #df1010 !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: bold !important;
    width: 385px;
}

.snackbar-success {
    background-color: #33cb79 !important;
    font-size: 16px !important;
    color: white !important;
    font-weight: bold !important;
    width: 385px;
}

.position-snackbar {
    padding-top: 41px;
    position: absolute !important;
    right: 0px !important;
}

@media screen and (max-width: 550px) {
    .position-snackbar {
        padding-top: 47px;
    }
}
