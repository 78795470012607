$primary: #335eea;
$primaryLight: #3e68f1;
$secondary: #506690;
$background: #f9fbfd;
$lightGrey: #e8ecf5;
$mediumGrey: #e2e2e2;
$white: #ffffff;
$lightBlack: #333333;
$lightBlue: #729ee1;
$purple: #744cc6;
$lightPurple: #dcd2f0;
$darkBlue: #314465;
$lightGreen: #ceeddc;
$lightPink: #f8f5fe;
$blue1: #14438b;
$blue2: #184fa4;
$blue3: #638ac5;
$blue6: #e3ecf9;
$grey: #757575;
$grey1: #585959;
$grey2: #999999;
$grey4: #dfe1e6;
$grey5: #e8e9eb;
$grey6: #fafbfc;
$grey10: #ededee;
$grey12: #eeeff2;
$dark: #344563;
$purple1: #43306d;
