@import "./colors.scss";
@import "./typography.scss";
@import "./components/popup.component.scss";
@import "./components/login.view.scss";
@import "./components/profile.view.scss";
@import "./components/app.container.scss";
@import "./components/card.component.scss";
@import "./components/footer.scss";
@import "./components/menu.scss";
@import "./components/dialog.scss";
@import "./components/privacy.view.scss";
@import "./components/home.view.scss";
@import "./components/filterbar.component.scss";
@import "./common.scss";
@import "./components//upload.component.scss";
@import "./components//download.component.scss";
@import "./components/text.input.outlined.scss";
@import "./components/stepper.scss";
@import "./components/tooltip.component.scss";
@import "./components/institute.view.scss";
@import "./components/book.view.scss";

body {
    background-color: $white;
    margin: 0;
    min-height: 100vh;
}

html {
    position: relative;
    min-height: 100%;
}

.back-drop {
    background: $white !important;
    mix-blend-mode: normal;
    opacity: 0.5 !important;
    z-index: 120100 !important;
    position: absolute !important;
    isolation: isolate;
}

.align-center {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    margin-bottom: 20px;
}

.pagination-grid {
    display: flex;
    flex-direction: row;
    margin-top: 40px !important;
    margin-bottom: 5px !important;
}

.pagination-button {
    width: fit-content !important;
    border-radius: 50% !important;
    min-width: 36px !important;

    &:hover {
        background-color: transparent !important;
        border-radius: 2px !important;
    }
    &::after {
        background-color: transparent !important;
        border-radius: 2px !important;
    }
}


.back-drop .image {
    opacity: 1 !important;
    animation-delay: 1 30;
}
.caption-container {
    padding-bottom: 16px;

    .caption {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: unset !important;
        color: $dark;
        height: 16px;
        //width: 345px;
    }

    .caption-id {
        background-color: $grey5;
        color: $dark;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $dark;
        height: 16px;
        width: 20px;
        padding: 0px 8px;
    }
}

.popup-position-absolute {
    .MuiDialog-paper {
        position: absolute !important;
        margin: 64px !important;
        top: 0% !important;
    }
}
