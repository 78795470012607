.dialog {
    .dialog-content {
        min-width: 300px;
    }
}
.dialog-display {
    height: 290px !important;
}

.dialogs-positive-title {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: $dark !important;
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
    margin: 0px 8px !important;
    margin-left: -14px !important;
}

.dialogs-positive-description {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $dark !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.dialogs-positive-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: #249688 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-color: #249688 !important;
    border-radius: 2px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.dialog-icon {
    width: 35px;
    padding-right: 13px;
    // padding-left: 20px !important;
}
.dialogs-negative-title {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: $dark !important;
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
    margin: 0px 8px !important;
    margin-left: -14px !important;
}

.dialogs-negative-description {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $dark !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.dialogs-negative-return-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue2 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.dialogs-negative-send-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: #ff1616 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-color: #ff1616 !important;
    border-radius: 2px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.dialog-negative-icon {
    width: 25px;
    padding-right: 13px;
}
.dialogs-informative-title {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: $dark !important;
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
    margin: 0px 8px !important;
    margin-left: -14px !important;
}

.dialogs-informative-description {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $dark !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    display: flex !important;
    flex-direction: column !important;
}

.dialogs-informative-learn-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue2 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.dialogs-informative-accept-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue3 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-color: $blue3 !important;
    border-radius: 2px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.dialog-informative-icon {
    width: 35px;
    padding-right: 13px;
}
.MuiPaper-rounded {
    border-radius: 2px !important;
    box-shadow: none !important;
    max-height: calc(100% - 44px);
}
.MuiDialogContent-root {
    padding: 0px 16px !important;
}
.MuiDialogActions-root {
    padding: 24px 16px 16px 16px !important;
    justify-content: flex-end !important;
    align-items: center !important;
}
