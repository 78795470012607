.card-root {
    min-width: 180px!important;
    border-radius: 20px!important;
    
    
}

.card-title {
    font-size: 10px!important;
    color: #344563;    
}

.card-subtitle {
    font-size:24px!important;
    font-weight: 700;
    color: #344563;
}

.card-pos {
    margin-bottom: 12px!important
}

.MuiCard {
    // The default props to change
    z-index: 5,
}