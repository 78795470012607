.app-container {
    display: flex;
    font-family: "Open-Sans", sans-serif !important;
    .appBar {
        width: calc(100% - 44px);
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
            margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        background-color: $white;
        filter: drop-shadow(0px 1px 2px rgba(31, 31, 31, 0.1)) !important;
    }

    .appBarLogin {
        width: 100%;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
            margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        margin-left: 240px;
        background-color: $white;
        filter: drop-shadow(0px 2px 4px rgba(31, 31, 31, 0.1)) !important;
    }

    .appBarLoginLogo {
        display: flex !important;
    }
    .w-40 {
        width: 40px;
    }

    .appBarShift {
        width: calc(100% - 72px);
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
            margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        margin-left: 240px;
        background-color: $white;
        filter: drop-shadow(0px 1px 2px rgba(31, 31, 31, 0.1));
    }

    .app-title {
        size: 16px !important;
        line-height: 20px !important;
        font-weight: bold !important;
        flex-grow: 1;
        color: $blue2;
        font-family: "Open-Sans", sans-serif !important;
    }

    .menuButton {
        margin-right: 36px;
        color: blue;
    }

    .hide {
        display: none;
    }

    .drawer {
        width: 240px;
        flex-shrink: 0;
        white-space: nowrap;
    }

    .drawerPaper {
        background-color: #E8E9EB !important;
        height: 100%;
        justify-content: space-between;
    }

    .drawerOpen {
        width: 72px;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

        .avatar {
            background-color: $lightBlue;
            color: $white;
            height: 44px;
            width: 44px;
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;

            &:hover {
                background-color: $mediumGrey;
            }
        }
    }

    .drawerClose {
        width: 44px;
        overflow-x: hidden;
        transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        font-size: small;
        .avatar {
            background-color: $lightBlue;
            color: $white;
            height: 26px;
            width: 26px;
            font-family: "Open Sans", sans-serif !important;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 24px;

            &:hover {
                background-color: $mediumGrey;
            }
        }
    }

    .toolbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        min-height: 58px;

        &:hover {
            cursor: pointer;
        }

        .shrinkText {
            white-space: normal;
        }
    }

    .toolbar-bottom {
        padding-bottom: 0px !important;
        margin-top: "auto";
        .arrow {
            align-items: center;
            justify-content: center;
            display: flex;
            height: 45px;
            .shiftTextLeft {
                position: absolute;
                bottom: 0;
            }
        }
    }
    .toolbar-divider1 {
        //background-color: $grey5;
        margin-bottom: 10px;
    }
    .toolbar-divider2 {
        //background-color: $grey5;
        margin: 10px 0px;
    }

    .content {
        padding: 24px;
        flex-grow: 1;
        margin-left: 0px;
    }

    .contentShrink {
        padding: 24px;
        flex-grow: 1;
        margin-left: 0px;
    }
}

.auth-view-button {
    background-color: $blue2 !important;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    height: 40px;
    //margin-top: 8px !important;
}

.MuiChip-root {
    font-family: "Open-Sans", sans-serif !important;
}

.MuiButton-root {
    font-family: "Open-Sans", sans-serif !important;
    min-width: 45px !important;
}

.MuiTypography-body2 {
    font-family: "Open-Sans", sans-serif !important;
}
.MuiInputBase-root {
    font-family: "Open-Sans", sans-serif !important;
}
.MuiMenuItem-root {
    font-family: "Open-Sans", sans-serif !important;
}
.MuiTypography-body1 {
    font-family: "Open-Sans", sans-serif !important;
    font-size: 12px !important;
}
.MuiFormLabel-root {
    font-family: "Open-Sans", sans-serif !important;
}
.Mui-focused {
    font-family: "Open-Sans", sans-serif !important;
}
.MuiTypography-h5 {
    font-family: "Open-Sans", sans-serif !important;
}
.MuiAvatar-root {
    font-family: "Open-Sans", sans-serif !important;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.button-primary-create:hover {
    background-color: $purple1 !important;
}
.MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded {
    border: 1px solid $grey4;
}
.MuiPaper-root.MuiPaper-elevation0.MuiPaper-rounded:hover {
    background-color: $grey12 !important;
}

.MuiDrawer-paper {
    overflow-y: hidden!important;
    overflow-x: hidden!important;
}

.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page:hover {
    background-color: $grey12 !important;

    border-radius: 2px !important;
}

button.pagination-button:hover {
    background-color: $grey12 !important;
    border-radius: 2px !important;
}
.tab-unSelected {
    border-bottom: 1px $grey4 solid !important;
}
.default-tab:hover {
    background-color: $grey12 !important;
    border-radius: 2px !important;
}

.clickable-icons {
    padding: 4px !important;
    &:hover {
        cursor: pointer;
        background-color: $grey12 !important;
        border-radius: 50% !important;
    }
}
.lowercase.clear-style.refresh-filter.clickable-icons {
    padding-bottom: 0px !important;
}
.remove-hover {
    background-color: transparent !important;
}

.pagination-button {
    width: fit-content !important;
    border-radius: 50% !important;
    min-width: 36px !important;

    &:hover {
        background-color: transparent !important;
        border-radius: 2px !important;
    }
    &::after {
        background-color: transparent !important;
        border-radius: 2px !important;
    }
}

.detail-title {
    font-family: "Open Sans";
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: $dark;
}

.MuiDialogTitle-root {
    padding: 16px 24px 8px 16px !important;
}

.button-primary-ok {
    background-color: $blue1 !important;
    color: $white !important;
}


