.upload-document-root {
    padding-top: 24px !important;
    .upload-document-table-container {
        height: max-content !important;
    }
}

.upload-container-root {
    display: flex;
    //height: 120px;
    background-color: $lightPink;
    width: 100%;

    .upload-container-content {
        width: 30%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 8px;
    }
    .upload-message-container {
        //width: 70%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 1%;
        padding-bottom: 8px;
    }
    .upload-info-message-container {
        padding: 16px 16px 8px 16px !important;
        .upload-info-message-head {
            font-family: "Open Sans", sans-serif !important;
            font-size: 14px;
            line-height: 20px;
            font-style: normal;
            font-weight: normal;
            color: $dark;
        }

        .upload-info-message {
            font-family: "Open Sans", sans-serif !important;
            font-size: 12px;
            line-height: 16px;
            font-style: normal;
            font-weight: normal;
            color: $dark;
        }
    }
    .upload-textfield-container {
        padding: 8px 16px 16px 16px !important;
    }
    .upload-error-message {
        font-weight: bold !important;
        color: red !important;
        font-family: "Open Sans", sans-serif !important;
    }
    .upload-browse-button {
        color: white !important;
        background-color: #14438b !important;
        font-family: "Open Sans", sans-serif !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 14px !important;
        line-height: 24px !important;
        text-transform: uppercase !important;
        margin-left: 16px;
        border-radius: 2px;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
        padding: 6px 12px !important;
        height: 32px !important;
        width: fit-content;
    }
}

.upload-document-list-file {
    float: left;
    width: 90%;
}
.upload-document-list-icon {
    float: right;
    padding-right: 8px !important;
    //width: 10%;
}
.upload-document-list-header {
    padding: 8px !important;
}

.table-head {
    background: white !important;
    color: $dark !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}
.table-head-upload{
    background: white !important;
    color: $dark !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    width:10%
}
.upload-table-width{
    width: 38% !important;
    background: white !important;
    color: $dark !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}
.table-cell {
    background: white;
    color: $dark !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    border-bottom: 0px !important;
    line-height: 20px !important;
    padding: 8px !important;
    max-width: 48px !important;
    width: 48px !important;
    overflow-x: visible !important;
    white-space: "normal";
    word-wrap: "break-word";
}

// style={{
//     whiteSpace: "normal",
//     wordWrap: "break-word"
//   }}

.table-cell-statistics {
    white-space: normal;
    word-wrap: break-word;
}

.table-container-statistics {
    min-width: 600px;
}

.table-cell-upload{
    background: white;
    color: $dark !important;
    font-weight: 400 !important;
    font-size: 14px !important;
   // border-bottom: 0px !important;
    //line-height: 20px !important;
    padding: 8px !important;
 
    //max-width: 48px !important;
    //width: 48px !important;
    overflow-x: visible !important;
}
.delete-icon-upload {
   // max-width: 0px !important;
    text-align: center !important;
}
.delete-icon{
    max-width: 10px !important;
}
.table-body-masterdata {
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;
    .upload-row{
    .MuiTableCell-root{
 padding:0px !important
       
    }}
}

.table-body{
    border-bottom: 1px solid rgba(224, 224, 224, 1) !important;

}
