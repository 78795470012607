.pt-4-accordion-top {
    padding-top: 4px !important;
}

.ml-24 {
    margin-left: 24px;
}

.pb-16-forced {
    padding-bottom: 16px !important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.PrivateNotchedOutline-root-2 {
    border: 1px solid $grey4 !important;
}

.MuiOutlinedInput-root {
    border-radius: 0px !important;
}

.profile-textField-disabled {
    background-color: #eeeeee !important;
    background: #eeeeee !important;
    border: none !important;
}

.grid-flex-forced {
    display: flex !important;
}
.chip-green.MuiChip-root{
    margin-top: 3px !important;
}
.flex-grow {
    flex-grow: 1 !important;
}
.popup-change-email{
    .MuiDialogContent-root{
        overflow-y: hidden !important;
    }
    .MuiTypography-colorTextSecondary{
        color: #344563 !important ;
    }
}

.profile-padding{
    .MuiAccordionDetails-root{
        padding: 8px 16px 16px 16px !important;
    }
}
.profile-button-popup {
    text-align: center;
    width: max-content !important;
    height: 32px !important;
    background-color: $blue1 !important;
    background: $blue1 !important;
    color: white !important;
    border-radius: 2px !important;
    //margin-left: 92%;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    padding: 4px 12px !important;
    text-transform: capitalize !important;    
}

.lowercase{
    text-transform: lowercase !important;
    padding-left: 5px;
}


.height{
    height: 32px !important;
    .MuiInputBase-input
    {font-size: 14px !important;
        height: 15px;
        padding-top:10px;
    }
    
}
.profile-cancel-button-popup {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue2 !important;

    &:hover {
        background-color: transparent !important;
    }
}

.profile-button-container {
    justify-content: flex-end;
    display: flex;
    align-items: flex-start;
    justify-self: flex-end;
    .MuiButton-root {
        min-width: unset !important;
        padding: unset !important;
    }

    .icon-buttons {
        display: flex;
        justify-content: space-between;
        button {
            border: black 1px solid;
            background: $blue1;
            margin-left: 10px;
            margin-right: 10px;
            width: 48px;
            height: 32px;
            opacity: 1;
                &.disabled {
                    opacity: 0.2;
                }
                &.secondary {
                    background: white;
                }
            }
    }

    .profile-button {
        width: max-content;
        display: flex !important;
        background: $blue1 !important;
        height: 32px !important;
        border-radius: 2px !important;
        margin-left: 16px !important;
        min-width: unset !important;

        .profile-button-text {
            color: white !important;
            font-weight: bold !important;
            text-transform: uppercase !important;
            padding: 0px 0px 0px 8px !important;
            line-height: 80%;
        }

        .profile-button-icon {
            padding: 0px 8px 0px 4px !important;
        }
    }
}

.identity-validated-at {
    padding-left: 8px;
    font-size: 15;
    color: #bbbbbb;
}

.text-style-profile {
    font-family: "Open Sans", sans-serif !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #181818 !important;
    text-transform: capitalize !important;
}

.accordionContainer {
    border: 0.1px solid lightgray !important;
    min-height: 32px !important;
    margin: 0px !important;
    .MuiAccordionSummary-content.Mui-expanded {
        margin: 0px !important;
    }
}

.accordion {
    background-color: #f5f5f5 !important;
    min-height: 0px !important;
    margin: 0px !important;
    height: 40px;

    .accordion-icon {
        margin: 0px 10px !important;
    }

    .accordion-title {
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px;
        line-height: 16px;
    }
}
.accordion-body {
    padding-top: 16px !important;
    .grid-initials {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile-email {
        background-color: #eeeeee !important;
        background: #eeeeee !important;
        height: 32px !important;
        flex-grow: 1 !important;
    }

    .avatar {
        background-color: $lightBlue;
        font-size: 16px;
        font-weight: 700;
        height: 44px;
        width: 44px;;
    }

    .chip-gray {
        background-color: #797979;
        margin-right: 2px;
        color: white;
        font-family: "Open Sans", sans-serif !important;
    }

    .chip-blue {
        background-color: $blue1;
        margin-right: 16px;
        color: white;
        font-family: "Open Sans", sans-serif !important;
        text-transform: capitalize !important;
        padding: 2px 8px 2px 8px !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
    .chip-red {
        background-color: #ff9991;
        color: $darkBlue;
        border-radius: 2px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        font-family: "Open Sans", sans-serif !important;
        .MuiChip-label {
            padding: 2px 8px !important;
        }
    }
    .chip-green {
        background-color: #d3eae7;
        color: $darkBlue;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        height: 24px !important;
        font-family: "Open Sans", sans-serif !important;
        .MuiChip-label {
            padding: 2px 8px !important;
        }
    }
    .privacy-body {
        margin-left: 2px;
    }
    .italic-font {
        font-style: italic;
    }

    .MuiFilledInput-input {
        padding: 10.5px 18.4px !important;
    }
    .margin-left {
        margin-left: 15px;
        @media screen and (max-width: 1280px) {
            margin-left: 0px;
        }
    }
}

.profile-ml{
    margin-left: 16px ;
}

.dialog-title {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    //padding: 16px 16px 24px 16px !important;
    .dialog-title-item {
        display: flex;
        align-items: center;
    }
    .dialog-title-typography {
        font-family: "Open Sans", sans-serif !important;
        font-weight: 700;
        padding-left: 12px;
        font-size: 18px;
        line-height: 24px;
        color: $dark;
    }
    .dialog-title-typography.MuiTypography-body1{
        font-size: 18px !important;
    } 
}

.mt-10-mb-0{
    margin-bottom: 0px !important;
    margin-top: 12px !important;

}

.MuiTypography-body1{
    font-size: 14px !important;
    line-height: 20px !important;
}
.dialog-title.MuiDialogTitle-root{
    padding: 16px 16px 24px 16px !important
}

.profile-privacy-description {
    font-family: "Open Sans", sans-serif !important;
    max-height: 270px;
    overflow-y: auto;
    overflow-x: auto;
    //margin-bottom: 15px;
    color: $dark !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: normal !important;
    font-style: normal !important;
}

.color-gray {
    color: $grey2 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
