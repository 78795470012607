.menu {
    align-items: center;
    width: "fit-content" !important;
    background-color: #E8E9EB !important;
    &:hover {
        background-color: $grey12 !important;
    }
}

.selected-item-menu {
    border-right: 1px solid $blue1 !important;
    background-color: #E8E9EB !important;
}

.menuOpen {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px !important;
    background-color: #E8E9EB !important;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: $darkBlue !important;
    padding-left: 8px !important;
    padding-right: 0px !important;
    width: fit-content !important;

    .formatted-message-style {
        text-overflow: ellipsis !important ;
        white-space: nowrap !important;
        overflow: hidden !important;
        width: 60px !important;
        display: inline-block !important;
        flex-direction: row !important;
        justify-content: center !important;
        text-align: center !important;
        font-weight: 600 !important;
        padding-top: 6px !important;
    }

    .menu-icon {
        min-width: 0;
    }
}

.menuClose {
    display: flex;
    height: 44px;
    background-color: #E8E9EB !important;
    padding-left: 11px !important;
}

.menu-shadow.MuiPaper-rounded {
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
        0px 0px 1px rgba(9, 30, 66, 0.31) !important;
    border-radius: 3px !important;
}

.menu-item {
    &:hover {
        background-color: #f5f6f7 !important;
    }
}
