.MuiButton-root:hover {
  text-decoration: undefined !important;
  background-color: undefined !important;
}

.checkbox-error-label {
  margin-left: -5px !important;
}

.check-circle-icon {
  color: #4caf50;
}

.error-circle-icon {
  color: #ff0033;
}

.d-flex {
  display: flex;
}

.my-1 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.links-style {
  font-family: "Open Sans", sans-serif !important;
}

.error-message {
  font-family: "Open Sans", sans-serif !important;
  color: red !important;
}

.common-header {
  font-size: 14px !important;
  line-height: 20px !important;
}

.common-body {
  font-size: 12px !important;
  line-height: 16px !important;
}

.MuiFormLabel-root {
  background-color: white !important;
}

.no-item-list {
  font-weight: normal !important;
  font-size: 16px !important;
  color: $grey1 !important;
}

.MuiFormHelperText-root {
  margin-top: 4px !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #df1010 !important;
}

.popup-width {
  .MuiDialog-paperWidthSm {
    max-width: 721px;
  }
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mr-16 {
    margin-right: 16px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.no-padding {
    padding: 0px !important;
}

.min-width {
  min-width: 95px !important;
}

.common-button {
  display: inline-flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 6px 12px !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 24px !important;
  background-color: #14438b !important;
  color: white !important;
  height: 32px !important;
  width: max-content !important;
}

.pagination-grid {
  display: flex;
  flex-direction: row;
  margin-top: 40px !important;
  margin-bottom: 5px !important;
}

.pagination-button {
  width: fit-content !important;
  border-radius: 50% !important;
  min-width: 36px !important;

  &:hover {
    background-color: transparent !important;
    border-radius: 2px !important;
  }
  &::after {
    background-color: transparent !important;
    border-radius: 2px !important;
  }
}

.w-100 {
  width: 100%;
}
.MuiPaginationItem-root {
  color: $blue1 !important;

  &:hover {
    background-color: transparent !important;
  }
}

.flex {
    display: flex !important;
}

.align-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
