.sticky-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 45px;
  width: 100%;
  overflow: hidden;
  background-color: $mediumGrey;

  .footer-toolbar {
    padding: 5px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $grey6;
    color: red !important;

    a {
      color: $dark;
      padding: 5px 10px;
      text-decoration: none;
      margin: 0 10px;

      &:hover {
        color: $primary;
      }
    }

    .language-select {
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
        margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
      width: calc(100% - 75px);
      overflow: hidden;
      margin-right: 24px;
      margin-left: 75px;
    }

    .circual-progress {
      position: absolute;
      right: 20px;
      //margin-top: -10px;
    }
  }
}

.sticky-footer2 {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 45px;
  width: 100%;
  overflow: hidden;
  background-color: $grey6;
  
  .footer-toolbar {
    padding: 5px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      color: $blue2 !important;
      padding: 5px 8px;
      text-decoration: none;
      margin: 0 8px;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      text-transform: capitalize;
  
      &:hover {
        color: $blue1;
        text-decoration-line: underline;
      }
    }

    .language-select {
      margin-right: 24px;
      .languageSelect {
        font-size: 14px !important;
        color: $grey1 !important;
      }
    }

    .circual-progress {
      position: absolute;
      right: 20px;
      //margin-top: -10px;
    }
  }
}

.footer-shift {
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: calc(100% - 44px);
  overflow: hidden;
}

.footer-null {
  width: 100%;
  overflow: hidden;
}

.WithStyles\(ForwardRef\(InputBase\)\)-input-1 {
  color: $grey1 !important;
  width: 85px !important;
  border: 1px solid #ced4da;
  padding: 7px;
  position: relative;
  border-radius: 0;
  background-color: #fff;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: 5px !important;
}
.MuiSelect-icon {
  top: calc(50% - 12px);
  color: $grey !important;
  right: 10px !important;
  position: absolute;
  pointer-events: none;
}
