.privacy-card-container {
    margin: 0 auto;
    max-width: 640px;
    align-items: center;
    display: flex;
    padding: 0px !important;

    .privacy-card {
        max-width: 640px;
        width: 100%;
        left: 50%;
        top: 64px;
        position: fixed;
        transform: translate(-50%, -0%);
        .privacy-title {
            margin-bottom: 0px !important;
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $dark !important;
        }
        .privacy-description {
            min-height: fit-content;
            margin-bottom: 24px;
            font-family: "Open Sans", sans-serif !important;
            font-style: normal;
            font-weight: normal !important;
            font-size: 14px !important;
            line-height: 20px !important;
            color: $dark !important;
        }
        .privacy-checkbox-selected {
            color: $grey1 !important;
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            padding-left: 8px !important;
            margin-bottom: 24px !important;
        }
        .privacy-checkbox-unselected {
            color: #181818 !important;
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            padding-left: 8px !important;
            margin-bottom: 24px !important;
        }
        .privacy-button {
            text-align: center;
            width: fit-content !important;
            height: 32px;
            background-color: $blue1 !important;
            background: $blue1 !important;
            color: white;
            border-radius: 2px !important;
            margin-left: 91%;
            font-family: "Open Sans", sans-serif !important;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 16px;
        }
        .privacy-modal {
            width: 640px;
            display: flex;
            flex-direction: column;
        }

        .privacy-icon {
            margin-right: 12px !important;
        }
        .privacy-title-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 24px;
        }
    }
}
.MuiCardContent-root:last-child {
    padding-bottom: 16px !important;
}
.ml-0 {
    margin-left: 0px !important;
}
.mb-24 {
    margin-bottom: 24px !important;
}
.ml-16 {
    margin-left: 16px !important;
}
.pb-20 {
    padding-bottom: 20px;
}
