.book-item-selected {
    background-color: #FAFBFC;
}

.book-item:hover {
    background-color: #FAFBFC;
}

.book-item {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    .book-avatar {
        margin: 8px 16px;
        border-radius: 10px;
        height: 52px;
        width: 52px;
    }
    .book-code {
        color: #344563 !important;
        background-color: #E8E9EB !important;
        padding: 0px 4px !important;
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px !important;
        line-height: 20px !important;

    }
    .book-title {
        font-family: "Open Sans", sans-serif !important;
        font-weight: bold;
        font-size: 14px !important;
        line-height: 20px !important;
        color: #314465 !important;
    }
    .book-level {
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px !important;
        line-height: 20px !important;
        color: #181818 !important;
    }
    .book-grade {
        color: #344563 !important;
        padding: 2px 8px !important;
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px !important;
        line-height: 20px !important;
        border-radius: 2px !important;
        background: linear-gradient(0deg, rgba(255,255,255,0.8), rgba(255,255,255,0.8)), #729EE1;
    }
}

.update-container-background {
    background-color: #FAFBFC !important;
    background: #FAFBFC !important;
}

.book-code-update-container-div {
    display: flex;
    justify-content: flex-end;
    .book-code-update-container {
        color: #344563 !important;
        background-color: #E8E9EB !important;
        padding: 0px 4px !important;
        font-family: "Open Sans", sans-serif !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.common-button-delete-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
