input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.position {
    top: -8% !important;
    z-index: 1000 !important;
    .MuiInputLabel-formControl {
        top: -8% !important;
        z-index: 1000 !important;
    }
}

.positionRemove {
    top: 0 !important;
    z-index: 1000 !important;
    .MuiInputLabel-formControl {
        top: 0 !important;
        z-index: 1000 !important;
    }
}

.height32 {
    height: 32px !important;
}

.textInpuVariableBackground {
    background-color: white !important;
}

.textInputBorder {
    //border: 1px solid $grey4 !important;
    //height: 32px !important;
    background-color: $grey10 !important;
    background: $grey10 !important;
    font-family: "Open Sans", sans-serif !important;
    border-radius: 0px !important;
    color: $grey2 !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
}

.MuiChip-root {
    margin-bottom: 4px !important;
    margin-top: -2px !important;
}

.textInputStyle {
    color: rgba(24, 24, 24, 1) !important ;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    //height: 32px !important;
    .MuiOutlinedInput-adornedEnd {
        padding-right: 8px !important;
    }
}

.textInputStyleDisabled {
    font-family: "Open Sans", sans-serif !important;
    //border: 1px solid $grey4 !important;
    border-radius: 0px !important;
    color: $grey2 !important;
}

.autoCompletemulti-height32 {
    .MuiInputBase-root {
        height: 32px !important;
    }
}

.textInputStyle :focus {
    background-color: unset !important;
    background: unset !important;
}

.error-input-style {
    border: 1px solid #df1010 !important;
}

.focused-input {
    border: 1px solid $blue1 !important;
}

.disabled-input {
    border: 1px solid $grey4 !important;
}

.textLabelInputStyleDisabled {
    font-family: "Open Sans", sans-serif !important;
    border-radius: 0px !important;
    color: $grey2 !important;
    background-color: unset !important;
    background: unset !important;
    z-index: 1000 !important;
    background-color: $grey10 !important;
    background: $grey10 !important;
}

.textLabelInputStyle {
    font-family: "Open Sans", sans-serif !important;
    border-radius: 0px !important;
    background-color: white !important;
    background: white !important;
    z-index: 1000 !important;
}

.textInputNoBorder {
    border: none !important;
}

.MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54) !important;
}

.privacy-checkbox-selected {
    color: #181818 !important;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding-left: 8px !important;
}
.privacy-checkbox-unselected {
    color: #181818 !important;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding-left: 8px !important;
}

.MuiOutlinedInput-inputAdornedEnd {
    padding-left: 14px !important;
}
