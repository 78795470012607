.institute-code-update {
  background-color: #e8e9eb;
  color: #344563;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px !important;
  padding: 0px 4px;
  width: fit-content;
  height: 20px;
}

.institute-card {
  .mb-10 {
    margin-bottom: 10px;
  }
  .card-item {
    padding: 8px 0 0 16px;
    display: contents;
    flex-direction: row;
  }

  .card-item2 {
    padding: 0px 0 0 16px;
    display: contents;
    flex-direction: row;
  }

  .header-institute {
    background-color: #e8e9eb;
    color: #344563;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 4px;
    width: fit-content;
    height: 20px;
  }

  .mt {
    margin-top: 8px;
  }

  .summary-institute {
    color: #314465;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    margin-inline-start: 8px !important;
    font-size: 14px;
  }

  .column1 {
    display: inline-flex;
    flex-direction: column;
    align-content: flex-end;
  }

  .columnAvatar {
    display: inline-flex;
    flex-direction: column;
    align-content: center;
  }

  .flex {
    display: flex;
    align-items: center;
    margin-inline-end: 20px;
  }

  .institute-description {
    align-items: flex-start;
    border-radius: 3px;
    padding: 2px 8px;
    color: #314465;
    overflow: hidden;
    flex-grow: 1;
  }

  .margin-bottom {
    margin-bottom: 5px;
    margin-right: 24px;
  }

  .italic {
    font-style: italic;
  }
  
  .margin-right-d {
    margin-right: 4px;
  }

  .padding {
    padding-top: 4px;
  }

  .align {
    align-items: center;
  }

  .dark-blue {
    color: #556690;
  }

  .institute-container {
    align-items: flex-start;
  }
  .light-gray {
    background-color: #e8ecf5;
    width: fit-content !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 350px;
    word-break: break-all;
  }

  .capitalize {
    text-transform: capitalize;
    display: flex;
    justify-content: flex-end;
    padding: 2px 8px;
    font-family: "Open Sans", sans-serif;
    color: #314465;
  }
}
.align-center {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.align-left {
  padding-top: auto !important;
  padding-bottom: 33px !important;
  justify-content: center;
}

@media (min-width: 1300px) {
  .align-center {
    padding-bottom: 20px;
  }
}

@media (max-width: 1299px) {
  .align-center {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    padding-bottom: 30px;
  }
}

.selected-page {
  background-color: $primary !important;
  color: $white !important;
}
.padding-bottom {
  padding-bottom: 10px;
}
.filter-icon {
  color: #3a71ea;
}
.vertical-line {
  border-left: 1px solid black;
  height: 37px;
  float: right;
}
.clear-style {
  float: right;
  margin-left: 10px !important;
}
.clear-filter {
  margin-left: auto !important;
}
.refresh-filter {
  margin-top: 2px;
}
.refresh-filter:hover {
  cursor: pointer;
}
.lowercase {
  text-transform: none !important;
}

.clear-btn-style {
  box-sizing: border-box !important;
  border-radius: 2px !important;
  width: auto;
  height: 32px;
  font-family: "Open Sans", sans-serif !important;
  color: $white !important;
  padding: 6px 12px !important;
  background-color: $blue1 !important;
}
.status-filter {
  .min-width {
    min-width: 100px;
    justify-content: space-between;
  }
}

.skills-container-job {
  background-color: $lightPink !important;
  padding: 10px !important;
  margin-top: 20px !important;
  min-height: 194px !important;
  height: fit-content !important;
  top: 387px !important;
  border-radius: 0px !important;
  .textfield-paper {
    background-color: white !important;
    border: 1px solid $grey4 !important;
    box-sizing: border-box !important;
  }

  .light {
    opacity: 0.8;
  }
}

.mb-24 {
  margin-bottom: 24px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.hide {
  display: none;
}

.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
ccc .selected-item-card {
  background-color: $grey6 !important;
}

.selected-chip {
  background-color: $purple !important;
  border-radius: 12px;
  color: rgb(255, 255, 255) !important;
  margin: 8px 8px 8px 0 !important;
}

.not-selected-chip {
  background-color: $purple !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.65;
  margin: 8px !important;
  border-radius: 12px;
}

.selected-chip-standard {
  background-color: $blue1 !important;
  color: rgb(255, 255, 255) !important;
  margin: 8px !important;
  border-radius: 12px;
}

.not-selected-chip-standard {
  background-color: $blue1 !important;
  color: rgb(255, 255, 255) !important;
  opacity: 0.65;
  border-radius: 12px;
  margin: 8px !important;
}
.chip {
  margin: 5px !important;

  .chip-font {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 20px;
    color: $white !important;
  }
}
.subtitle-data {
  padding-top: 5px !important;
  color: $dark !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 20px;
  color: #ffffff !important;
}

.subtitle-data {
  padding-top: 5px !important;
  color: #344563 !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: uppercase;
}
.calendar {
  margin-right: -6px;

  .MuiIconButton-root:hover {
    background-color: transparent;
  }
}
.MuiIconButton-root {
  padding: 0px !important;
}

.subtitle-project {
  color: $dark !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: uppercase;
}
.subtitle-skills {
  color: $dark !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  padding-bottom: 16px !important;
  padding-top: 10px;
  margin: 0px !important;
}

.button-create-profile {
  margin-top: 12px !important;
  min-width: 174px !important;
  width: 230px !important;
  height: 36px !important;
  padding: 4px !important;
  background-color: $blue1 !important;
  border-radius: 2px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
}
.subtitle-button-profile {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: $white !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  margin: 0px 4px !important;
}

.textfield-paper {
  background-color: white !important;
  border: 1px solid $grey4 !important;
  box-sizing: border-box !important;
}
.textfield fieldset {
  border-radius: 0px !important;
}

.button-standard {
  &:hover {
    background-color: transparent !important;
  }
  left: calc(50% - 420px / 2) !important;
  top: calc(50% - 50px / 2) !important;
  margin-top: 20px !important;
  width: 420px !important;
  border-color: $blue1 !important;
  height: 50px !important;
  margin-bottom: 8px !important;
}

.subbtitle-button-standard {
  left: calc(50% - 368px / 2) !important;
  top: calc(50% - 24px / 2) !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  padding-top: 5px;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: $blue1 !important;
}
.divider {
  display: flex;
  flex-direction: row;
  width: 98%;

  .width-50 {
    width: 50%;
    margin-top: 20px !important;
  }
  .text {
    color: #000000 !important;
    margin-top: 10px;
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    padding: 0px 8px;
  }
}
.text-paper {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  line-height: 20px !important;
  color: $dark !important;
}
.text2-paper {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: $dark !important;
  margin-bottom: 18px !important;
}

.selected-tab {
  color: $blue2 !important;
  font-family: "Open-Sans", sans-serif !important;
  text-transform: capitalize !important;
}

.default-tab {
  color: $darkBlue !important;
  font-family: "Open-Sans", sans-serif !important;
  text-transform: capitalize !important;
}

.tab-underline {
  background-color: $blue1;
  height: 1px;
  position: absolute;
}

.button-primary-create {
  background-color: $blue1 !important ;
  color: $white !important;
  font-weight: bold !important;
  font-family: "Open-Sans", sans-serif !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 20px !important;
  border-radius: 2px !important;
  width: 104px;
  height: 36px;
  box-shadow: none !important;
}

.bgimg {
  background-image: url("../../icons/Mix/aaaa.svg");
  width: 132px;
  height: 24px;
  //margin-top: 6px !important;
}

.color-text {
  color: #181818 !important;
}

.MuiPaginationItem-root {
  color: $blue1 !important;

  &:hover {
    background-color: transparent !important;
  }
}
.pagination-grid {
  display: flex;
  flex-direction: row;
  margin-top: 40px !important;
  margin-bottom: 5px !important;
}

.institute-update-appBar {
  background-color: $grey6 !important;
  color: $dark !important;
}

.institute-update-appBar-title {
  background-color: $blue1 !important;
  color: $white !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: bolder !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
}
.institute-update-appBar-title :hover {
  background-color: transparent !important;
}

.institute-update-vd {
  display: flex;
  justify-content: flex-end;
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

.institute-text-Style {
  color: rgba(24, 24, 24, 1) !important ;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  background-color: rgba(254, 254, 254, 1) !important;
}
.institute-customer-Style {
  color: rgba(24, 24, 24, 1) !important ;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  height: 40.5px !important;
  background-color: rgba(254, 254, 254, 1) !important;
}

.institute-text-senority {
  color: rgba(24, 24, 24, 1) !important ;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  background-color: rgba(254, 254, 254, 1) !important;
  height: 39px !important;
}
.institute-text-Style-Autocomplete {
  color: rgba(24, 24, 24, 1) !important ;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
}

.institute-text-Style :disabled {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  background-color: rgba(254, 254, 254, 1) !important;
}
.select {
  color: #9c9c9d !important ;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  background-color: rgba(254, 254, 254, 1) !important;
}
.institute-text-Style-Disabled {
  background-color: $grey10 !important;
  border: 1px solid $grey4 !important;
  box-sizing: border-box !important;
  border-radius: 0px !important;
  color: $grey2 !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}
@media (min-width: 600px) {
  .MuiGrid-grid-sm-2 {
    max-width: 13.666667% !important;
  }
}

@media (min-width: 600px) {
  .MuiGrid-grid-sm-5 {
    max-width: 30.333333% !important;
  }
}

.pr-12 {
  padding-right: 12px !important;
}

.flex-institute-row {
  display: flex;
  flex-direction: row;
}

.flex-institute-header {
  width: fit-content;
  display: flex;
  overflow: hidden;
  align-items: baseline;
}

.mr-20 {
  margin-right: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-7{
  margin-bottom: 7px !important;
}
.mb-16 {
  margin-bottom: 16px !important;
}

.institute-update-container {
  margin-bottom: 40px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pl-8 {
  padding-left: 8px !important;
}
.pl-12 {
  padding-left: 12px;
}

.pr-8 {
  padding-right: 8px !important;
}
.pt-24 {
  padding-top: 24px !important;
}

.bg-white {
  background-color: white !important;
}

.mt-4 {
  margin-top: 4px !important;
}
.mt-16 {
  margin-top: 16px;
}

.institute-item {
  &:hover {
    background-color: $grey6;
    cursor: pointer;
  }
  border: 1px solid $grey4;
  border-radius: 1px !important;
}

.mr-3 {
  margin-right: 3.5px !important;
}

.popup-skills-ok {
  text-align: center;
  width: fit-content !important;
  height: 28px;
  background-color: $blue1 !important;
  color: $white !important;
  border-radius: 2px !important;
  margin-left: 6px !important;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  margin-bottom: 16px !important;
  margin-right: 16px !important;
  box-shadow: none !important;
}
.popup-skills-cancel {
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: uppercase !important;
  color: $blue2 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px !important;
  margin-bottom: 12px !important;

  &:hover {
    background-color: transparent !important;
  }
}

/* CREATE */
.d-flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.horizontal-padding-2pc{
  padding: 0 2%
}

/* UPDATE */
.update-institute-box{
  display: flex;
  > header {
    align-items: flex-end
  }
}