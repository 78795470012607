.login-background {
    position: absolute;
    width: 400px;
    height: 696px;
    left: -302.75px;
    top: -78.96px;

    background: linear-gradient(116.3deg, #47a5f0 18.36%, $blue2 89.8%);
    border-radius: 80px;
    transform: rotate(19deg);
}
.lss-login-card-container {
    margin: 0 auto;
    max-width: 400px;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    min-height: 80vh;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 30px;
    position: sticky;

    .lss-login-card {
        max-width: 400px;
        width: 100%;
        background: $white;
        box-shadow: 0px 4px 20px 5px rgba(31, 31, 31, 0.1) !important;

        .lss-title {
            color: $darkBlue;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 16px;
            font-family: "Open Sans", sans-serif;
        }
        .lss-input-container {
            margin-bottom: 16px;
        }
        .lss-input-container2 {
            margin-bottom: 24px;   
        }

        .lss-button-container {
            margin-bottom: 36px;
            margin-top: 24px;
        }
    }
}

.login-image-style {
    position: absolute;
    right: 6px;
    bottom: 47px;
    width: 23%;
}

.bip-logo-style {
    left: 6px;
    right: 6px;
    width: 15%;
}
.login-logo-style {
    background-color: #3a8edc;
    height: 30px;
    width: 30px;
    transform: rotate(45deg);
    .login-logo-small {
        background-color: $blue2;
        height: 10px;
        width: 10px;
    }
}

.prec-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: $darkBlue !important;
    margin-bottom: 16px !important;
}

.pres-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $darkBlue !important;
    margin-bottom: 16px !important;
}

.prec-button-text {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 24px !important;
}

.popup-display {
    height: 305px !important;
}
.popup-validate {
    height: 290px !important;
}
.popup-not-validate {
    height: 350px !important;
}
.popup-change-password {
    height: 350px !important;
}
.MuiDialog-paperWidthXs {
    max-width: 450px !important;
    background-color: $grey6 !important;
}

.popup-title {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 18px !important;
    line-height: 24px !important;
    color: $dark !important;
    flex: none !important;
    order: 1 !important;
    flex-grow: 0 !important;
    margin-bottom: -7px;
}

.popup-description {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $dark !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.MuiDialogActions-root {
    padding: 0px !important;
}

.popup-description-add {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: $dark !important;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.popup-ok-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $white !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: $blue1 !important;
    border-radius: 2px !important;
    margin-right: 16px !important;
    margin-bottom: 16px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.popup-icon {
    width: 35px;
}
.popup-accept-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $white !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: $blue1 !important;
    border-radius: 2px !important;
    margin-right: 16px !important;
    margin-bottom: 16px !important;
    width: fit-content;
}
.popup-cancel-button-email {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue2 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-transform: capitalize !important;
    //margin-right: 16px !important;
    //margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.popup-learn-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue2 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 10px !important;
    margin-bottom: 10px !important;

    &:hover {
        background-color: transparent !important;
    }
}
.popup-cancel-button {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 20px !important;
    text-transform: uppercase !important;
    color: $blue2 !important;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-right: 8px !important;
    margin-bottom: 14px !important;

    &:hover {
        background-color: transparent !important;
    }
}
