.steps-container {
    background-color: $lightGrey;
    margin: 10px 20px;
    padding: 10px;
}

.mt-10 {
    margin-top: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}