
.order {
  margin-right: 12px !important;
}

.icon-cancel {
  position: relative;
  .icon-cancel-component {
    padding: 2px;
    position: absolute;
    top: 28%;
    left: 89%;
  }
  .icon-cancel-component-highlightofficon {
    color: #808483;
    font-size: 15px !important;
    position: absolute;
    top: 0px;
    background-color: $white;
    padding-left: 12px !important;
    padding-right: 6px !important;
  }
}

.borderStyle-fieldset {
  border-radius: 0;
  border: none;
  .MuiInputBase-root {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #314465;
  }
}

.textInputStyle :focus {
  background-color: white !important;
}

// .MuiSelect-outlined.MuiSelect-outlined {
//   padding-right: 0px !important
// }

.divider-style {
  background-color: #dfe1e6 !important;
  margin-right: 16px !important;
}

.status-style {
  padding-right: 10px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  color: #314465 !important;
  & :focus {
    background-color: #f5f6f7 !important;
  }
}

.status-style-change {
  padding-right: 10px !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  color: #314465 !important;
  background-color: #E3ECF9  !important;
}

.claret-down {
  width: 31px;
}
.placeholder-text-style {
  width: 240px;
  height: 32px;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #181818 !important;
  padding-right: 8px !important;
  .search-position {
    position: absolute;
    right: 8px;
  }
}

.order-text-style {
  height: 32px !important;
  box-sizing: border-box !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #314465 !important;
}

.orderStyle {
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 3px;
}
.order-button-background{
  background-color: #F5F6F7!important;
   display: flex!important;
    height: 33px!important;
}
.order-button-background-color{
  background-color: #E3ECF9!important;
   display: flex!important;
    height: 33px!important;
}
.width-82 {
  width: 82px;
}
.width-180 {
  width: 180px;
}

.MuiOutlinedInput-inputAdornedEnd {
  padding-left: 8px !important;
}

.text-color {
  font-size: 14px !important;
  color: #181818 !important;
  padding-left: 8px;
}

.link-color {
  font-size: 14px !important;
  color: $blue2 !important;
}

.MuiAutocomplete-paper {
  border: 1px solid $grey4 !important;
  margin-top: 0px !important;
  border-radius: 0px !important;
}

.MuiAutocomplete-listbox {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  color: #181818;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: $grey5 !important;
}
.MuiMenu-list {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
}

.ml-10 {
  //margin-left: 10px;
  filter: drop-shadow(0px 2px 4px rgba(31, 31, 31, 0.1)) !important;
}
.pl-5 {
  padding-left: 5px;
}

.display-none {
  display: none !important;
}

.show-clear-filter {
  display: flex;
  direction: row;
  align-self: flex-start;
}

.light-grey {
  background-color: #f5f6f7;
}

.advanced-search-icon {
  height: 30px;
  width: 24px;
}
.MuiButton-label {
  display: contents !important;
  padding: 0px 0px !important;
  text-transform: none !important;
  font-weight: bold;
}

.advanced-button {
  display: inline-flex !important;
  padding: 0px 32px 0px 0px;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 0px !important;
}

.button-order-filter {
  padding-left: 6px !important;
  padding-right: 6px !important ;
  min-width: 15px !important;
}

.container-status {
  padding-bottom: 11px;
  padding-top: 21px;
}

.distance-filter {
  padding-bottom: 20px;
  padding-top: 20px;
}

.filter-satus {
  position: absolute;
  left: 73px;
}

.menu-item-staus {
  padding-right: 20px !important;
  padding-left: 20px !important ;
  box-sizing: unset !important;
  width: 148px !important;
  height: 32px !important;
}

.button-border-filter{
  border : solid  1px !important;
  border-left: 0px !important;
  border-color: #c5c5c5!important;
}