.popup {
    &-container {
        .buttons {
            font-family: $SansSerif;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            .secondary {
                color: $blue2;
                &:hover {
                    background-color: transparent;
                }    
            }
            .primary {
                text-transform: uppercase;
                height: 32px;
                background-color: $blue1;
                color: white;
                border-radius: 2px;
                padding: 4px 12px;
            }
        }
    }
}